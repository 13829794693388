<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="bg-white mb-1 rounded">
          <b-button
            variant="flat-secondary"
            class="btn-icon"
            @click="MoveBack()"
          >
            <feather-icon
              size="20"
              icon="ArrowLeftIcon"
            />
          </b-button>
          <span>
            <span class="text-capitalize">{{ $t('actions.back') }}</span>
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row class="mx-0 bg-white mb-1 rounded">
      <b-col>
        <b-card-text
          style="margin-top: 25px"
          class="text-bold"
        >
          <feather-icon
            icon="ClockIcon"
            size="20"
          />
          {{ user && user === 'all'
            ? $t('navigation-menu.attendance')
            : $t('navigation-menu.total-days')
          }}
          <b
            v-if="!user || user === 'all' "
            class="text-bold"
          >
            {{ currentMonthName }}
          </b>
          <span v-if="user && user != 'all' && attendance">
            : {{ attendance.length }} {{ $t('labels.days') }}
          </span>
        </b-card-text>
      </b-col>
      <b-col
        v-if="user && user == 'all'"
      >
        <b-form-group>
          <label class="mr-1">{{ $t('page-link-module.filter') }}</label>
          <v-select
            v-model="selectedClass"
            :options="searchRooms"
            :reduce="(room) => room.id"
            label="name"
            :placeholder="$t('choose-classroom')"
            @search="searchClasses"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="!isStudent"
        md="3"
      >
        <div class="custom-search d-flex justify-content-end w-100">
          <b-form-group class="w-100">
            <label class="mr-1">{{ $t('messages.seachLabel') }}</label>
            <v-select
              v-model="user"
              :placeholder="$t('search-user')"
              :reduce="(item) => item.value"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="users"
              label="text"
              debounce="200"
              @input="getAttendance"
            />
          </b-form-group>
        </div>
      </b-col>
      <b-col
        v-show="user && user !== 'all' "
        md="3"
      >
        <div class="custom-search d-flex justify-content-end w-100">
          <b-form-group class="w-100">
            <label class="mr-1">{{ $t('year') }}</label>
            <div class="d-flex align-items-center">
              <b-form-select
                v-model="year"
                :options="yearRange"
                @input="getAttendance"
              />
            </div>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-overlay
      v-if="isSpecificUser && user"
      :show="isProcessing"
      class="bg-white p-1 rounded"
    >
      <b-row>
        <b-col
          v-for="i in 12"
          :key="i"
          md="3"
          class="border-bottom"
          :class="{ ' bg-light-success': i == currentMonth && year == currentYear }"
        >
          <month-blocks
            :year="year"
            :month="i"
            :user-id="user"
            class="pb-1 pt-1"
            :attendance="attendance"
          />
        </b-col>
      </b-row>
    </b-overlay>
    <b-row v-else>
      <b-col sm="12">
        <b-overlay :show="isLoading">
          <all-user-attendance :attendance="filteredAttendance ? filteredAttendance : allAttendance" />
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BFormGroup, BCardText, BRow, BCol, BFormSelect, BOverlay, BButton,
} from 'bootstrap-vue'
// import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT } from '@/const/userType'
import useApollo from '@/@core/graphql/useApollo'
import MonthBlocks from './components/MonthBlocks.vue'
import AllUserAttendance from './components/AllUserAttendance.vue'

const moment = require('moment')

export default {
  components: {
    BFormGroup,
    BCardText,
    BRow,
    BCol,
    vSelect,
    MonthBlocks,
    BFormSelect,
    BOverlay,
    AllUserAttendance,
    BButton,
  },
  data() {
    const thisYear = (new Date()).getFullYear()
    const yearRange = []
    for (let i = 2021; i <= thisYear; i += 1) {
      yearRange.push(i)
    }
    return {
      user: this.$route.params.id ?? 'all',
      users: [
        {
          text: this.$i18n.tc('user-module.all-users'),
          value: 'all',
        },
      ],
      isProcessing: false,
      year: thisYear,
      yearRange,
      attendance: [],
      currentMonth: Number(moment().format('M')),
      currentYear: Number(moment().format('YYYY')),
      currentMonthName: moment().format('MMMM'),
      allAttendance: [],
      self: getUserData(),
      searchRooms: [],
      selectedClass: '',
      filteredUsers: null,
      filteredAttendance: null,
      isLoading: false,
    }
  },
  computed: {
    isSpecificUser() {
      return this.user !== 'all'
    },
    isStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
  },
  watch: {
    '$route.params.id': function (newValue) {
      this.user = newValue ?? 'all'
      this.getAttendance()
      this.checkMissingUserList()
    },
    selectedClass(value) {
      if (value == null) this.filteredAttendance = null
      else this.getStudentsByClass()
    },
  },
  mounted() {
    if (this.isStudent) {
      this.user = this.self.id
      this.getAttendance()
      return
    }
    this.getUser()
    this.checkMissingUserList()
    if (this.user && this.year) {
      this.getAttendance()
    }
    this.searchClasses('')
  },
  methods: {
    MoveBack() {
      if (this.$route.query.classId) {
        this.$router.push({ name: `${this.AUTH_USER().usertype}-view-students`, params: { id: this.$route.query.classId } })
        return
      }
      this.GO_BACK()
    },
    getAttendance() {
      if (!this.isStudent && !this.$route.params.id && this.user !== 'all') {
        this.$router.push({ name: `${this.self.usertype}-view-attendance`, params: { id: this.user } })
        return
      }
      const startDate = `${this.year}-01-01`
      const endDate = moment(startDate).endOf('year').format('YYYY-MM-DD')
      this.isProcessing = true
      const params = {
        user_id: this.user || 'all',
        start_date: startDate,
        end_date: endDate,
        year: this.year,
      }

      this.storeFilterQuery('attendance-filter', params)
      if (params.user_id === 'all') {
        useJwt.getAllStudentAttendance().then(response => {
          let maxPresent = 0
          let minPresent = Number.POSITIVE_INFINITY
          response.data.data.forEach(item => {
            if (item.date.length > maxPresent) {
              maxPresent = item.date.length
            }
            if (item.date.length < minPresent) {
              minPresent = item.date.length
            }
          })
          const hasAttendanceMoreThanTwo = response.data.data.length > 2
          const allAttendance = response.data.data.map(item => {
            let presentPercent = 0
            if (item.date[0]) {
              const totalDays = moment(item.date[0]).daysInMonth()
              presentPercent = (item.date.length / totalDays) * 100
              presentPercent = Math.round(presentPercent, 2)
            }
            let variant
            if (item.date.length === maxPresent) {
              variant = 'success'
            } else if (item.date.length === minPresent) {
              variant = 'danger'
            }
            return {
              name: item.fullname,
              total_days: item.date.length,
              presentPercent: `${presentPercent}%`,
              presentPercentNum: presentPercent,
              year: moment().year(),
              month: moment().month() + 1,
              user_id: item.user_id,
              dates: item.date,
              _rowVariant: hasAttendanceMoreThanTwo && variant,
            }
          })
          allAttendance.sort((a, b) => b.presentPercentNum - a.presentPercentNum)
          this.allAttendance = allAttendance
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
        return
      }
      // if not all user get single user attendance
      useJwt.getStudentAttendance({
        params,
      }).then(response => {
        const attendance = response.data.data.map(day => moment(day).format('YYYY-MM-DD'))
        this.attendance = [...new Set(attendance)]
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    checkMissingUserList() {
      if ((this.user !== 'all') && !this.users.map(user => user.value).includes(this.user)) {
        this.users.push({
          text: this.$route.query.fullname,
          value: this.user,
        })
      }
    },
    getUser() {
      useApollo
        .getUserDropDown({
          params: {
            page: 1,
            perPage: 1000,
            type: 'student',
          },
        })
        .then(response => {
          const responseData = response.data.users.data
          const allUser = responseData.map(user => ({
            text: user.fullname,
            value: user.id,
          }))
          this.users = [{ text: this.$i18n.tc('user-module.all-users'), value: 'all' }, ...allUser]
          this.checkMissingUserList()
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    searchClasses(value) {
      useApollo
        .searchRooms(value, this.self.id)
        .then(res => {
          this.searchRooms = res.data.searchRooms
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
    },
    getStudentsByClass() {
      this.isLoading = true
      this.filteredUsers = []
      useJwt.getStudentsOfClass(this.selectedClass).then(response => {
        this.filteredUsers = response.data?.data.map(student => student.id)
        if (!this.filteredUsers.length) this.isLoading = false
      }).finally(() => {
        this.isLoading = false
        this.filteredAttendance = this.allAttendance.filter(attendance => this.filteredUsers.indexOf(attendance.user_id) !== -1)
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.border-bottom {
  border-bottom:  1px solid #cccbd1 !important;
}
</style>
